<template>
  <div>
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="user"
      :get-user="getUser"
      :user="user"
    />
  </div>
</template>

<script>

import User from '@/application/models/User.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/users/api/index.js';

export default {
  name: 'UserShowLayout',
  components: {
    KSubMenu,
  },
  data: () => ({
    user: null,
  }),
  computed: {
    menuItems: () => [
      {
        title: 'Overzicht',
        route: { name: 'settings.users' },
      },
    ],
    parentMenu() {
      return [
        {
          title: this.$tc('user.title', 2),
          route: { name: 'settings.users' },
        },
      ];
    },
  },
  watch: {
    '$route.params.userId': {
      immediate: true,
      handler() {
        this.getUser();
      },
    },
  },
  methods: {
    async getUser() {
      const response = await show(this.$route.params.userId);
      this.user = new User().mapResponse(response.data.data);
    },
  },
};
</script>
